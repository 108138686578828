.pp-masonry
  background-color: var(--lot-bg-color)
  margin: 0 0 #{$grid-gutter-width} 0
  line-height: 1
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-between
  height: $masonry-height
  overflow: hidden
  border-radius: $masonry-border-radius
  box-shadow: 0 0 4px 2px rgba($pp-black, 0.1)
  border: 2px solid var(--masonry-border-color)
  .masonry-header
    height: 1.65rem
    display: flex
    margin-top: auto
    clear: both
    i
      font-size: 1rem
      &.bookmark-add:hover
        color: $pp-yellow
    .masonry-price
      text-align: right
      font-weight: bold
      letter-spacing: 0
      +rem('font-size', 18px)
      line-height: 0.9
      order: 1
      color: var(--masonry-price)
      white-space: nowrap
      width: 100%
      span
        +rem('font-size', 14px)
        display: flex
        padding: .2rem 0 0 0
        margin-left: auto
        &::before
          content: "\A"
          white-space: pre
      //&.pp-highlight
        background-color: $pp-orange
        color: $pp-white
        border-radius: 0.2rem
        padding: 0.1rem 0.3rem
        margin-top: -0.05rem
    .pp-liked
      color: $pp-orange
      margin-right: 0.25rem
  &.loan-price
    .masonry-body
      .image-placeholder,
      .post-images
        height: auto
    .short-loan
      margin-top: auto
    .masonry-header
      margin-top: 0
  .masonry-title
    line-height: 1.2
    //margin-top: 0.5rem
    //white-space: nowrap
  .masonry-body
    padding: 0
    text-align: left
    overflow: hidden
    font-size: .85rem
    position: relative
    strong
      color: var(--masonry-header-color)
      font-weight: $masonry-header-font-weight
      font-size: $masonry-header-font-size
      line-height: 1.15
      //.single-line
        height: 35px
        margin-bottom: 0.34rem
    .masonry-image
      //height: $masonry-image-height
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      background-color: var(--image-placeholder-bg)
      border-radius: $input-border-radius
      position: relative
      margin-bottom: .35rem
      aspect-ratio: 4/3
      img
        width: 100%
        max-height: 370px
        margin: 0
      .masonry-image-count
        position: absolute
        right: 0
        bottom: 0
        background-color: rgba($pp-black, 0.5)
        border-radius: 3px
        color: $pp-white
        +rem("font-size", 10px)
        font-weight: 500
        padding: 5px
      span
        border-radius: 0
    .masonry-text
      word-break: break-word
      color: var(--masonry-text)
      margin-top: .15rem
  &.seen
    border-width: 2px
  .pp-heart
    color: $pp-yellow
    text-shadow: none
  .pp-heart-o
    color: $icon-gray
  &.highlighted
    &.is-bookmarked
      border-color: $pp-yellow
      i.pp-heart
        color: $pp-yellow
        &:hover
          color: darken($pp-yellow, 10%)
  &.recommended
    border-color: $recommended-border
    box-shadow: 0 0 4px 2px rgba($recommended-shadow, 0.5)
  a
    color: var(--masonry-header-color)
    text-decoration: inherit
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: flex-start
  .lot-id
    margin: .25rem 1rem 0 0
  .pp-footer
    position: absolute
    bottom: 0
    width: 100%
    background: var(--masonry-footer-bg)
    .btn-secondary
      border-top: none
      border-bottom: none
      border-right: none
      border-radius: 0
      &:hover
        border-color: $pp-btn-secondary-border
  &.priority-picture
    .masonry-header
      background-color: var(--lot-bg-color)
    .thumbnails-wrapper
      max-height: none!important
      .post-images
        height: auto
        aspect-ratio: 3/4
        max-height: none
      .thumbnail-gallery
        position: static
    .image-placeholder
      height: 16.1rem
      font-size: 9rem
      margin: 0
    .lot-id
      margin-top: 0
    .masonry-title
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      line-height: 1.2
    .masonry-body
      padding: 0
      position: relative
    .filter-list
      margin: 0
  &.has-footer,
  &.pp-masonry-filters
    border-color: $pp-filters
    height: 325px
    overflow: visible
    &.loan-price
      .short-loan
        margin-top: 0
    .masonry-header
      margin-bottom: 2rem
    .pp-menu
      box-shadow: $box-shadow-multilevel-category-menu
  .estate-list
    margin-bottom: .25rem
    .pp-landing
      margin-right: .25rem
    .filter-item
      flex: 0 0 50%
      white-space: nowrap
  .street-filter
    margin-left: 1.2rem
  .type
    position: absolute
    bottom: 1.5rem
  &.real-estate
    .post-images,
    .masonry-image
      aspect-ratio: 3/2
    //  height: $masonry-image-height
    .old-price
      margin: 0
      &.is-rent
        right: 4.2rem
    .masonry-title
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      display: block!important

.pp-content-wrapper
  .pp-masonry,
  .pp-list-view
    &.pp-blocked
      border-color: $pp-masonry-blocked-border !important
      background-color: $pp-masonry-blocked-bg !important
      a
        opacity: .5
        display: block
      .pp-menu
        border-radius: 0 0 .2rem .2rem
      .dropup
        .dropdown-menu
          height: calc(100% + 2px)
          top: -1px
      .pp-footer,
      .list-footer
        border-radius: 0 0 .2rem .2rem
      .gradient-hide
        display: none

.pp-footer
  border-top: 1px solid var(--border-color)
  padding: 0 .5rem
  display: flex
  align-items: center
  i
    color: $pp-bookmark-icons
    padding: .5rem
    cursor: pointer
    font-size: 1rem
    &.icon-dropup
      padding: 0 .5rem .25rem 0
      font-size: .5rem
      transform: rotate(180deg)
      &:hover
        color: inherit
  .pp-more
    margin: 0
    padding: .5rem
  .badge-default
    margin: 0
  span
    font-weight: 500
    +rem("font-size", 12px)
    text-transform: uppercase
    overflow: hidden
    text-overflow: ellipsis
  .pp-footer
    border-color: rgba($pp-white, 0.3) !important
  .form-check-label
    padding: 1rem
    position: absolute
    left: 0
    top: 0
    width: 8rem
    &:before,
    &:after
      left: .5rem
      top: .5rem

.next-prev-block
  display: flex
  .masonry-block-width
    width: 49%
    &:nth-child(2)
      margin-left: auto
  .pp-masonry
    margin: 0!important

.masonry-block-width
  +rem("max-width", 250px)
  &:not(.pptype-discount)
    .is-discount
      border-color: $cat-discount

.is-my-lot
  border-color: $pp-green !important
  .pp-my-lots
    &:before
      color: $pp-green
    .pp-landing
      color: $pp-green

.my-bookmarks
  border-color: $pp-bookmarks
  .pp-masonry
    .gradient-hide
      bottom: 2rem
      left: 1px

.lot-stats
  font-size: 0.875rem
  color: $pp-green
  line-height: 1
  margin-left: 1rem

.thumbnails-wrapper
  margin-bottom: .25rem
  cursor: pointer
  .post-images
    width: 100%
    height: auto
    aspect-ratio: 4/3
    //height: $masonry-image-height
    //object-fit: cover
    &.bg-size-contain
      object-fit: contain
  .thumbnail-gallery
    position: relative
    .gallery-info
      position: absolute
      right: 0
      bottom: .15rem
      .gallery-info-badge
        border-radius: 50% !important
        display: inline-block
        background: rgba(0,0,0,0.4)
        width: 28px
        height: 28px
        line-height: 28px
        text-align: center !important
        margin-right: 0.5rem
        margin-bottom: 0.38rem
        i
          color: white !important
          line-height: inherit !important
        .pp-gallery-panorama
          width: 24px
          height: 28px
    .see-more-overlay
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, 0.6)
      color: #fff
      display: flex
      align-items: center
      justify-content: center
      font-size: 18px
      text-align: center
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg
    height: 2rem
    width: 1rem
    position: static
  .image-gallery-left-nav,
  .image-gallery-right-nav
    height: 100%
    padding: 0
    width: 2.6rem
    text-align: right
    visibility: hidden
    opacity: 0
    transition: visibility 0s, opacity 0.5s linear
  .image-gallery-left-nav
    text-align: left

.classified:hover
  .thumbnails-wrapper
    .image-gallery-left-nav,
    .image-gallery-right-nav
      visibility: visible
      opacity: 1

.masonry-view
  .classified-map
    margin-left: #{$grid-gutter-width}

.discount-price
  background: $red-BF2827
  position: absolute
  z-index: 1
  color: $pp-white
  right: 0.5rem
  top: .5rem
  font-size: .85rem
  height: 2rem
  width: 2rem
  display: flex
  border-radius: 50%
  align-items: center
  justify-content: center

.discount-price-classified
  @extend .discount-price
  font-size: 1rem
  height: 3rem
  width: 3rem

.discount-price-classified-mobile
  @extend .discount-price
  position: absolute
  top: 1rem
  right: 1rem

.old-price
  text-decoration: line-through
  color: $pp-gray
  text-shadow: none
  position: absolute
  bottom: 1.3rem
  left: .5rem
  font-weight: normal
  margin-bottom: .05rem

.fav-wrap
  margin-left: auto
  display: flex
  align-items: center
  position: relative
  flex-direction: column
  right: .25rem
  justify-content: end
  bottom: .5rem
  .fav-count
    font-weight: 500
    font-size: 0.65rem !important
    justify-content: center
    color: $icon-gray
    display: flex
  .preloader-wrapper.amount
    margin: 0

.classified-sold
  position: absolute
  z-index: 1
  font-weight: bold
  width: 5rem !important
  top: 0
  bottom: 0
  right: 0
  left: 0
  margin: auto

.progress-indicator
  display: flex
  gap: 3px
  position: absolute
  left: 50%
  transform: translateX(-50%)
  width: 100%
  justify-content: center
  bottom: .25rem
  .progress-indicator-line
    width: 35px
    height: 2px
    border-radius: 100px
    transition: background 0.3s ease
    background: rgba($pp-white, 0.3)
    &.active
      background: $pp-yellow
