.social-ico
  display: block
  background-image: url(../img/social-login-logos.svg)
  +rem('background-size', $icon-size-lg)
  background-repeat: no-repeat
  +rem('width', $icon-size-lg)
  +rem('height', $icon-size-lg)
  overflow: hidden
  &.icon-facebook
    background-position: 0 0
  &.icon-google
    +rem('background-position', 0 -32px)
  &.icon-inbox
    +rem('background-position', 0 -64px)
  &.icon-draugiem
    +rem('background-position', 0 -96px)
  &.icon-x
    +rem('background-position', 0 -128px)
    filter: var(--filter-invert)
  &.icon-linkedin
    +rem('background-position', 0 -160px)
  &.icon-pinterest
    +rem('background-position', 0 -192px)
  &.icon-mail
    +rem('background-position', 0 -224px)
  &.icon-whatsapp
    +rem('background-position', 0 -256px)
  &.icon-instagram
    +rem('background-position', 0 -288px)
  &.icon-telegram
    +rem('background-position', 0 -320px)
  &.icon-messenger
    +rem('background-position', 0 -352px)
  &.icon-tiktok
    +rem('background-position', 0 -384px)

.pp-lang
  background-image: url(../img/flags.svg)
  background-size: 1rem
  overflow: hidden
  padding: 0 .5rem
  display: inline-block
  +rem("height", 12px)
  &.lv
    background-position: 0 0
  &.ru
    background-position: 0 1.5rem!important
  &.en
    background-position: 0 .75rem!important

.payment
  &__method
    a
      font-size: 1rem
      span
        display: inline-flex
        align-items: center
        min-width: 12rem
    .payment-ico
      margin-right: .5rem
      display: block
      background-image: url(../img/payment-logos.png)
      +rem('background-size', 30px)
      background-repeat: no-repeat
      +rem('width', 30px)
      +rem('height', 30px)
      overflow: hidden
      &.icon-paypal
        background-position: 0 0
      &.icon-hbl
        +rem('background-position', 0 -40px)
      &.icon-sebl
        +rem('background-position', 0 -80px)
      &.icon-ccard
        +rem('background-position', 0 -120px)

.social-wrap
  padding: 0 0 .25rem 0
  display: flex
  div
    margin-left: .5rem
    border-left: 1px solid $pp-gray-border
    padding-left: .5rem
  div:first-of-type
    margin-left: 0
    border-left: none
    padding-left: 0
  .input-wrapper
    .form-control
      padding-right: 4.5rem
  .pp-trash-o
    position: absolute
    right: 1rem
    top: 1rem
