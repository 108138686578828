.list-view
  .classified
    position: relative
  .pp-list-view
    border-radius: $masonry-border-radius
    margin: 0 0.4rem 0.8rem
    border: 2px solid var(--masonry-border-color)
    box-shadow: 0 0 4px 2px rgba($pp-black, 0.1)
    background-color: var(--lot-bg-color)
    a
      color: inherit
      text-decoration: inherit
      width: 100%
      display: flex
      flex-direction: column
      justify-content: center
    button
      border-radius: 0.2rem
    &.highlighted
      &.is-bookmarked
        border-color: $pp-yellow
        i.pp-heart
          color: $pp-yellow
          &:hover
            color: darken($pp-yellow, 10%)
    .pp-heart,
    .pp-heart-o
      color: $pp-green
    &.seen
      border-width: 2px
    &.highlighted
      &.is-bookmarked
        border-color: $pp-yellow
        i.pp-heart
          color: $pp-yellow
          &:hover
            color: darken($pp-yellow, 10%)
    &.is-discount
      border-color: $cat-discount
    &.recommended
      border-color: $recommended-border
      box-shadow: 0 0 4px 2px rgba($recommended-shadow, 0.5)
  .filter-list
    gap: 0
  .pp-footer
    span
      margin: inherit
  .dropup
    .dropdown-menu
      width: 38%!important
  .list-body
    padding: 0
    min-height: 2.2rem
    display: flex
    height: 8rem
    overflow: hidden
    position: relative
    &:hover
      .thumbnails-wrapper .image-gallery-left-nav,
      .thumbnails-wrapper .image-gallery-right-nav
        opacity: 1
        transition: ease-in 500ms
    .pp-heart,
    .pp-heart-o
      font-size: 1rem
      z-index: 1
      right: 0
      color: $pp-yellow
      &.pp-liked
        color: $pp-orange
        right: .5rem
    .pp-heart-o
      color: $icon-gray
    .bookmark-loader
      position: absolute
      bottom: .35rem
      right: .5rem
    .list-image
      position: relative
      margin: 0 1rem 0 0
      max-width: 11.25rem
      height: 8rem!important
      background-repeat: no-repeat
      background-size: cover
      background-position: center
      background-color: $image-placeholder-bg
      border-radius: $input-border-radius 0 0 $input-border-radius
      display: flex
      flex-direction: column
      justify-content: center
      text-align: center
      width: 100%
      .list-image-count
        position: absolute
        right: 0
        bottom: 0
        background-color: rgba($pp-black, 0.5)
        border-radius: $masonry-border-radius 0 0 0
        color: $pp-white
        +rem("font-size", 10px)
        font-weight: 500
        padding: 5px
    .list-text
      font-size: 1rem
      +rem('line-height', 17px)
      word-break: break-word
      color: var(--masonry-text)
    .list-title
      +rem('font-size', 14px)
      color: var(--masonry-text)
      line-height: 1
      max-width: 90%
      margin-top: .2rem
      word-break: break-word
    .list-price
      text-align: right
      font-weight: bold
      letter-spacing: 0
      +rem('font-size', 18px)
      line-height: 0.9
      white-space: nowrap
      display: block
      color: var(--masonry-price)
      padding-right: .5rem
      span
        +rem('font-size', 14px)
        &::before
          content: "\A"
  .fav-wrap
    position: absolute
    .fav-count
      height: .75rem
  .dropup
    .dropdown-menu
      width: auto
      right: -1px
      left: inherit
      top: inherit
      bottom: .3rem!important
  .list-view-right
    display: flex
    flex-direction: column
    text-align: right
    flex-wrap: wrap
    .old-price
      top: 1.5rem
      right: .5rem
      &.is-rent
        position: absolute
        top: 1.2rem
        right: 2.3rem
    .short-loan
      position: absolute
      top: 1.5rem
      right: 0
      height: 1.3rem
  .list-content
    margin-top: .25rem
    width: 100%
    position: relative
    strong
      height: 1.5rem
      overflow: hidden
  .is-my-lot
    .list-body
      .list-image
        border-radius: 0.2rem 0 0 0
  .lot-id
    margin-top: .15rem
    margin-right: 1rem
  .thumbnails-wrapper
    .post-images
      max-height: 8rem
  //.pp-visited
    bottom: 0.75rem
    right: 2rem
  .discount-price
    bottom: .5rem
  .badge-recommended
    font-size: 0.75rem
    padding: 0.15rem 0.55rem 0.15rem 0.15rem

.street-filter
  color: var(--masonry-text)

.filter-list
  display: flex
  flex-wrap: wrap
  gap: .25rem 0
  .pp-landing
    margin-right: .5rem
  div
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    width: 50%
    padding: 0.1rem

.estate-list
  .pp-landing
    margin-right: .5rem

.classified-map
  cursor: pointer
  +rem("margin-bottom", 7px)
  background-color: $pp-white
  box-shadow: 0 0 1px $pp-gray-border
  border: 1px solid $pp-gray-border
  border-radius: $masonry-border-radius
  width: 100%
